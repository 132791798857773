import React from 'react';
import ReactOnRails from 'react-on-rails';

const CsvUploader = () => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const path = window.location.pathname.replace('response_management', 'upload_csv');

  return (
    <div>
      <input
        type="file"
        accept=".csv"
        disabled={isUploading}
        onChange={(e) => {
          const file = e.target.files[0];
          if (file) {
            setIsUploading(true);
            // Work nicely with Rails CSRF protection
            const csrfToken = ReactOnRails.authenticityToken();

            const formData = new FormData();
            formData.append('file', file);
            formData.append('authenticity_token', csrfToken);
            fetch(path, {
              method: 'POST',
              body: formData,
            })
              .then((response) => response.json())
              .then((json) => {
                if (json.error) {
                  setError(json.error);
                  return;
                }
                setError([]);
                const { results } = json;

                alert(`File uploaded successfully.\n
  - Respones: ${results.updated} updated.\n
  - Themes: ${Object.keys(results.themes).length} detected`);

                window.location.reload();
              })
              .catch((error) => {
                console.error(error);
                alert('Error uploading file');
                alert(error);
              })
              .finally(() => {
                setIsUploading(false);
                e.target.value = null;
              });
          }
        }}
      />
      {error?.length > 0 && (
        <div>
          <span>Error</span>: <span style={{ color: 'red' }}>{error}</span>
        </div>
      )}
    </div>
  );
};
export default CsvUploader;

import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

const headersWithExemplarCount = (exemplarCount) => [
  { field: 'exemplar', headerName: `Is Exemplar (${exemplarCount})`, width: 130 },
  { field: 'theme', headerName: 'Theme', width: 250 },
  { field: 'response_value', headerName: 'Response', width: 1200 },
  {
    field: 'other_themes',
    headerName: 'Other Themes',
    width: 250,
    renderCell: (params) => {
      return <pre>{JSON.stringify(params.value)}</pre>;
    },
  },
];

const ResponsesTable = ({ selectedResponses, openModal, setSelectedResponseIndex, countData, selectedQuestion }) => {
  const handleRowClick = (params, _, detail) => {
    const rowIndex = detail.api.getRowIndexRelativeToVisibleRows(params.row.id);
    setSelectedResponseIndex(rowIndex);
    openModal(params.row);
  };

  const counts = (countData || {})[selectedQuestion]?.themes || {};

  const exemplarCount = Object.values(counts).reduce((acc, { exemplar }) => (exemplar ? acc + exemplar : acc), 0);
  const headers = headersWithExemplarCount(exemplarCount);

  return (
    <DataGridPro
      rows={selectedResponses}
      columns={headers}
      editMode="row"
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          position: 'sticky',
          top: 0,
          zIndex: 1, // Optional: Adjust z-index to ensure header stays above rows
          backgroundColor: 'white', // Optional: Set background color to match the grid
        },
      }}
      disableRowSelectionOnClick
      disableColumnSorting
      disableColumnFilter
      onRowClick={handleRowClick}
    />
  );
};

export default ResponsesTable;

import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

import SlideTableNavigator from './slide_table_navigator';

const headersWithSuperExemplarCount = (superExemplarCount) => [
  { field: 'school', headerName: 'School', width: 200 },
  { field: 'usedQuestion', headerName: 'Question', width: 200 },
  { field: 'super_exemplar', headerName: `Super Exemplar (${superExemplarCount})`, width: 180 },
  { field: 'theme', headerName: 'Theme', width: 250 },
  { field: 'usedText', headerName: 'Response', width: 1200 },
];

const extendResponse = (response, themesShorthands) => {
  const usedTheme = response.themes.find((theme) => themesShorthands[theme.theme] === response.theme);
  const usedQuestion = usedTheme.question_override || response.question;
  const usedText = usedTheme.text_overrides || response.response_value;

  return {
    ...response,
    usedQuestion,
    usedText,
  };
};

const SlidesSimulator = ({
  selectedResponses,
  themesShorthands,
  focusedResponse,
  allQuestionIds,
  selectedQuestion,
  countData,
  mergeUpdates,
  exemplarCounts,
  themesDisplayOrder,
  tabularQuestionResponseTheme,
  selectedTheme,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isModalOpen && !event.target.matches('[data-override]')) {
        if (event.key === 'ArrowLeft') {
          setSelectedResponseIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (event.key === 'ArrowRight') {
          setSelectedResponseIndex((prevIndex) => Math.min(prevIndex + 1, selectedResponses.length - 1));
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen, close]);

  const selectedResponse = selectedResponses[selectedResponseIndex];

  const handleRowClick = (params, _, detail) => {
    const clickedResponse = selectedResponses.find((r) => r.responseId === params.row.responseId);
    const responseIndex = selectedResponses.indexOf(clickedResponse);

    setSelectedResponseIndex(responseIndex);
    setIsModalOpen(true);
  };
  const extendedResponses = selectedResponses.map((response) => extendResponse(response, themesShorthands));

  const counts = (countData || {})[selectedQuestion]?.themes || {};
  const superExemplarCount = Object.values(counts).reduce((acc, { superExemplar }) => (superExemplar ? acc + superExemplar : acc), 0);
  const headers = headersWithSuperExemplarCount(superExemplarCount);

  return (
    <>
      <SlideTableNavigator
        close={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        selectedQuestion={selectedQuestion}
        selectedResponse={selectedResponse}
        selectedResponses={extendedResponses}
        setSelectedResponseIndex={setSelectedResponseIndex}
        themesShorthands={themesShorthands}
        selectedResponseIndex={selectedResponseIndex}
        countData={exemplarCounts}
        focusedResponse={focusedResponse}
        allQuestionIds={allQuestionIds}
        mergeUpdates={mergeUpdates}
        themesDisplayOrder={themesDisplayOrder}
        tabularQuestionResponseTheme={tabularQuestionResponseTheme}
        selectedTheme={selectedTheme}
      />

      <DataGridPro
        rows={extendedResponses}
        columns={headers}
        editMode="row"
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            position: 'sticky',
            top: 0,
            zIndex: 1, // Optional: Adjust z-index to ensure header stays above rows
            backgroundColor: 'white', // Optional: Set background color to match the grid
          },
        }}
        disableRowSelectionOnClick
        disableColumnSorting
        disableColumnFilter
        onRowClick={handleRowClick}
      />
    </>
  );
};

export default SlidesSimulator;

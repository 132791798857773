import React from 'react';

const labelFor = (themeId, tabularQuestionResponseTheme, themesShorthands) => {
  if (themeId === 'all') {
    return 'All';
  }

  const themeShorthand = themesShorthands[themeId];
  const themePrevalence = tabularQuestionResponseTheme.find((theme) => theme.theme === themeShorthand)?.prevalence;

  return `${themePrevalence}% - ${themeShorthand}`;
};

const sortByPrevalence = ({ availableThemeIds, themesShorthands, tabularQuestionResponseTheme }) => {
  return availableThemeIds.sort((a, b) => {
    const aPrevalence = tabularQuestionResponseTheme.find((theme) => theme.theme === themesShorthands[a])?.prevalence;
    const bPrevalence = tabularQuestionResponseTheme.find((theme) => theme.theme === themesShorthands[b])?.prevalence;

    return bPrevalence - aPrevalence;
  });
};

const SurveysReportFilters = ({
  questionIds,
  selectedQuestion,
  setSelectedQuestion,
  selectedTheme,
  setSelectedTheme,
  themesShorthands,
  availableThemes,
  tabularQuestionResponseTheme,
  availableOrgs,
  selectedSchool,
  setSelectedSchool,
}) => {
  const sortedThemes = sortByPrevalence({ availableThemeIds: availableThemes, themesShorthands, tabularQuestionResponseTheme });
  const schoolOptions = [
    { value: 'all', label: 'All' },
    ...availableOrgs.map((school) => ({ value: school.id, label: school.name })).sort((a, b) => a.label.localeCompare(b.label)),
  ];

  return (
    <div>
      <h2>Filters</h2>
      <div>
        <label htmlFor="school">School</label>
        <br />
        <select id="school" value={selectedSchool} onChange={(e) => setSelectedSchool(e.target.value)}>
          {schoolOptions.map((school) => (
            <option key={school.value} value={school.value}>
              {school.label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="question">Question</label>
        <br />
        <select id="question" value={selectedQuestion} onChange={(e) => setSelectedQuestion(e.target.value)}>
          {questionIds.map((questionId) => (
            <option key={questionId} value={questionId}>
              {questionId}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="theme">Theme</label>
        <br />
        <select id="theme" value={selectedTheme} onChange={(e) => setSelectedTheme(e.target.value)}>
          {['all', ...sortedThemes].map((themeId) => {
            return (
              <option key={themeId} value={themeId}>
                {labelFor(themeId, tabularQuestionResponseTheme, themesShorthands)}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SurveysReportFilters;

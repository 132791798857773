import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';

const STATS_COLUMNS = [
  { field: 'theme', headerName: 'Theme', width: 150 },
  { field: 'count', headerName: 'Count', width: 70 },
  { field: 'exemplar', headerName: 'Exemplar', width: 70 },
  { field: 'superExemplar', headerName: 'Super Exemplar', width: 70 },
  {
    field: 'prevalence',
    headerName: 'Prevalence',
    width: 100,
    valueFormatter: (value) => `${value}%`,
  },
];

const StatsTable = ({ tabularQuestionResponseTheme }) => {
  return (
    <DataGridPro
      rows={tabularQuestionResponseTheme}
      columns={STATS_COLUMNS}
      editMode="row"
      initialState={{
        sorting: {
          sortModel: [{ field: 'prevalence', sort: 'desc' }],
        },
      }}
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          position: 'sticky',
          top: 0,
          zIndex: 1, // Optional: Adjust z-index to ensure header stays above rows
          backgroundColor: 'white', // Optional: Set background color to match the grid
        },
      }}
      disableRowSelectionOnClick
    />
  );
};

export default StatsTable;

import React, { useEffect, useState } from 'react';
import ReactOnRails from 'react-on-rails';
import StatsTable from './stats_table';
import ResponsesTable from './responses_table';
import SurveysReportFilters from './filters';
import Modal from 'react-modal';
import ResponseTableNavigator from './response_table_navigator';
import SlidesSimulator from './slide_simulator';
import { aggregateCounts, tabulizeData, tabulizeResponseData } from './aggregations';
import CsvUploader from './csv_uploader';
import { updateResponses } from './response_actions';

const themeTemplate = (overrides) => {
  const allowedKeys = ['theme', 'text_overrides', 'exemplar', 'super_exemplar', 'question_override', 'source'];
  const filteredData = Object.keys(overrides).reduce((obj, key) => {
    if (allowedKeys.includes(key)) {
      obj[key] = overrides[key];
    }
    return obj;
  }, {});
  return filteredData;
};
const saveResponseData = (data, forceFailure = null) => {
  const themes = data.themes.map((theme) => themeTemplate(theme));
  const payload = { id: data.id, themes: themes };

  const path = window.location.pathname.replace('response_management', 'set_responses');

  const baseHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  // Work nicely with Rails CSRF protection
  const headerWithCSRF = ReactOnRails.authenticityHeaders({ ...baseHeaders });

  fetch(path, {
    method: 'PATCH',
    headers: forceFailure ? baseHeaders : headerWithCSRF,
    body: JSON.stringify({ surveys_report: payload }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      response.json();
    })
    .catch((error) => {
      alert(`Update failed for ${data.id} with error:
            ${error}`);
    });
};

const compose =
  (...funcs) =>
  (initialValue) => {
    return funcs.reduce((acc, func) => func(acc), initialValue);
  };

// CALCULATIONS
const linkableQuestions = (response) => response.themes.map((theme) => theme.question_override || response.question_title);

const filterResponse = (response, filters) =>
  (filters.question === undefined || filters.question === 'all' || linkableQuestions(response).includes(filters.question)) &&
  (filters.theme === undefined || filters.theme === 'all' || response.themes.some((theme) => theme.theme === filters.theme)) &&
  (filters.school === undefined || filters.school === 'all' || response.participant?.school === filters.school) &&
  (filters.exemplar === undefined || filters.exemplar === 'all' || response.themes.some((theme) => theme.exemplar === filters.exemplar));
const sortByLongestResponse = (responses) => responses.sort((a, b) => b.response_value.length - a.response_value.length);

const filterResponses = ({ responses, filters }) => ({ responses: responses.filter((r) => filterResponse(r, filters)), filters });
const selectExemplars = ({ responses, filters }) => ({ responses: responses.filter((r) => r.themes.some((t) => t.exemplar)), filters });
const orderResponses = ({ responses, _filters }) => ({ responses: sortByLongestResponse(responses), filters: _filters });

const selectResponses = compose(filterResponses, orderResponses);
const selectSlideReponses = compose(filterResponses, selectExemplars, orderResponses);

const SurveysReport = ({ originalResponses, themes, themesShorthands, themesDisplayOrder }) => {
  const [responses, setResponses] = useState(originalResponses);

  const mergeUpdates = ({ action, options }) => {
    const newResponses = updateResponses({ responses, action, options });
    const newResponse = newResponses.find((r) => r.id === options.responseId);
    setResponses(newResponses);
    return saveResponseData(newResponse);
  };

  const [selectedSchool, setSelectedSchool] = useState('all');

  const responsesForAggregation = filterResponses({ responses, filters: { school: selectedSchool } }).responses;
  const counts = aggregateCounts(responsesForAggregation, selectedSchool);
  const exemplarResponsesForAggregation = filterResponses({ responses: responsesForAggregation, filters: { exemplar: true } }).responses;
  const exemplarCounts = aggregateCounts(exemplarResponsesForAggregation, selectedSchool);

  const questionIds = Object.keys(counts);

  const [allQuestionIds, setAllQuestionIds] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(questionIds[0]);
  const [availableThemes, setAvailableThemes] = useState(Object.keys(counts[selectedQuestion]?.themes || {}));
  const [selectedTheme, setSelectedTheme] = useState('all');
  const [selectedResponses, setSelectedResponses] = useState([]);
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [aggregationCountTableData, setAggregationCountTableData] = useState([]);
  const [availableOrgs, setAvailableOrgs] = useState([]);
  const [slideResponses, setSlideResponses] = useState([]);

  const open = () => {
    setIsModalOpen(true);
  };
  const close = () => {
    setIsModalOpen(false);
  };

  Modal.setAppElement(document.querySelector('body'));

  const selectedResponse = selectedResponses[selectedResponseIndex];
  const focusedResponse = (responseId) => responses.find((r) => r.id === (responseId || selectedResponse?.responseId));

  useEffect(() => {
    const questionIds = new Set();
    originalResponses.forEach((response) => questionIds.add(response.question_title));
    setAllQuestionIds(Array.from(questionIds));
  }, [originalResponses]);

  useEffect(() => {
    const slideResponses = selectSlideReponses({
      responses,
      filters: { question: selectedQuestion, theme: selectedTheme, school: selectedSchool },
    }).responses;

    setSlideResponses(tabulizeResponseData(slideResponses, themesShorthands, selectedTheme));
  }, [selectedTheme, selectedQuestion, responses, selectedSchool]);

  useEffect(() => {
    const themes = Object.keys(counts[selectedQuestion]?.themes || {});
    setAvailableThemes(themes);

    const orgsMap = {};
    responses.forEach((response) => (orgsMap[response.participant?.school] = response.participant?.school));
    setAvailableOrgs(Object.keys(orgsMap).map((orgId) => ({ id: orgId, name: orgsMap[orgId] })));
  }, [selectedQuestion, themes, responses, selectedSchool]);

  useEffect(() => {
    setSelectedResponseIndex(0);
  }, [selectedQuestion, themes, selectedSchool]);

  useEffect(() => {
    const filteredResponses = selectResponses({
      responses,
      filters: { question: selectedQuestion, theme: selectedTheme, school: selectedSchool },
    }).responses;

    setSelectedResponses(tabulizeResponseData(filteredResponses, themesShorthands, selectedTheme));
  }, [selectedTheme, selectedQuestion, responses, selectedSchool]);

  useEffect(() => {
    setAggregationCountTableData(tabulizeData(counts, selectedQuestion, themesShorthands));
  }, [selectedQuestion, themesShorthands, responses, selectedSchool]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isModalOpen && !event.target.matches('[data-override]')) {
        if (event.key === 'ArrowLeft') {
          setSelectedResponseIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        } else if (event.key === 'ArrowRight') {
          setSelectedResponseIndex((prevIndex) => Math.min(prevIndex + 1, selectedResponses.length - 1));
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen, close]);

  return (
    <div>
      <CsvUploader />
      <SurveysReportFilters
        questionIds={questionIds}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        selectedTheme={selectedTheme}
        setSelectedTheme={setSelectedTheme}
        themesShorthands={themesShorthands}
        availableThemes={availableThemes}
        tabularQuestionResponseTheme={aggregationCountTableData}
        availableOrgs={availableOrgs}
        selectedSchool={selectedSchool}
        setSelectedSchool={setSelectedSchool}
      />
      <div>
        <h2>Stats</h2>
        <div style={{ height: '400px' }}>
          <StatsTable tabularQuestionResponseTheme={aggregationCountTableData} />
        </div>
      </div>
      <div>
        <h2>Responses</h2>
        <div style={{ height: '400px' }}>
          <ResponsesTable
            selectedResponses={selectedResponses}
            openModal={open}
            focusedIndex={selectedResponseIndex}
            setSelectedResponseIndex={setSelectedResponseIndex}
            countData={exemplarCounts}
            selectedQuestion={selectedQuestion}
          />
        </div>
      </div>
      <div>
        <h2>Slides Simulator</h2>
        <SlidesSimulator
          exemplarCounts={exemplarCounts}
          selectedResponses={slideResponses}
          themesShorthands={themesShorthands}
          focusedResponse={focusedResponse}
          mergeUpdates={mergeUpdates}
          allQuestionIds={allQuestionIds}
          countData={exemplarCounts}
          selectedQuestion={selectedQuestion}
          themesDisplayOrder={themesDisplayOrder}
          tabularQuestionResponseTheme={aggregationCountTableData}
          selectedTheme={selectedTheme}
        />
      </div>

      <ResponseTableNavigator
        selectedQuestion={selectedQuestion}
        selectedResponse={selectedResponse}
        selectedResponses={selectedResponses}
        themesShorthands={themesShorthands}
        selectedTheme={selectedTheme}
        setSelectedResponseIndex={setSelectedResponseIndex}
        mergeUpdates={mergeUpdates}
        isModalOpen={isModalOpen}
        close={close}
        selectedResponseIndex={selectedResponseIndex}
        countData={aggregationCountTableData}
        allQuestionIds={allQuestionIds}
        themesDisplayOrder={themesDisplayOrder}
        tabularQuestionResponseTheme={aggregationCountTableData}
      />
      <div style={{ width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
        <button onClick={() => saveResponseData(originalResponses[0], 'forceFailure')}>Test Theme Update Failure</button>
      </div>
    </div>
  );
};

export default SurveysReport;

// Some sane defaults for react-modal instances.
// For more flexibility (e.g. media-queries) we may want to revisit this,
// or just pass in your own styles as a prop.
export const DefaultModalStyles = {
  overlay: {
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    position: 'relative',
    width: '74%',
    minHeight: '50vh',
    maxHeight: '80vh',
    margin: 'auto',
    padding: '2.5rem 3rem',
  },
};

export const LinkModalStyles = {
  overlay: {
    // zIndex: 200,
    zIndex: 999999999,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
  },
  content: {
    width: 500,
    height: 'fit-content',
    padding: '2rem',
    margin: 'auto',
    position: 'absolute',
    zIndex: 201,
    top: '50%',
    transform: 'translateY(-50%)',
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  },
};

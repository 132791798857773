import React, { useState, useEffect } from 'react';

const TABLEAU_CHAR_LIMIT = 295;

const ThemeResponseOverrideTextArea = ({ value, onFinalChange }) => {
  const [text, setText] = useState(value);
  const [fitsOnTableau, setFitsOnTableau] = useState(value.length <= TABLEAU_CHAR_LIMIT);

  useEffect(() => {
    setFitsOnTableau(text.length <= TABLEAU_CHAR_LIMIT);
  }, [text]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <textarea
        rows={3}
        data-override
        style={{ width: '857px', borderColor: fitsOnTableau ? 'black' : 'red' }}
        onChange={(e) => setText(e.target.value)}
        onBlur={() => {
          if (!(text === value) && !(text === '')) {
            onFinalChange(text);
          }
        }}
        value={text}
      />
      {!fitsOnTableau && <div style={{ fontSize: '12px', fontWeight: 'bold', color: 'red' }}>Response is too large for Tableau.</div>}
    </div>
  );
};

export default ThemeResponseOverrideTextArea;

import React from 'react';
import Modal from 'react-modal';
import { DefaultModalStyles } from '../../constants/react_modal_styles';
import { useEffect, useRef, useState } from 'react';
import ThemeResponseOverrideTextArea from './theme_response_override_textarea';

const orderThemesBySelectedTheme = (themes, selectedTheme) => {
  if (selectedTheme === 'all') {
    return themes;
  }
  return themes.sort((a, b) => (b.theme === selectedTheme) - (a.theme === selectedTheme));
};

const ResponseTableNavigator = ({
  close,
  isModalOpen,
  selectedQuestion,
  selectedResponse,
  selectedResponses,
  setSelectedResponseIndex,
  themesShorthands,
  selectedResponseIndex,
  countData,
  allQuestionIds,
  mergeUpdates,
  themesDisplayOrder,
  tabularQuestionResponseTheme,
  selectedTheme,
}) => {
  const [showQuestionsListTooltip, setShowQuestionsListTooltip] = useState(false);

  const counts = {};
  if (selectedQuestion) {
    countData.forEach((count) => {
      counts[count.themeId] = { total: count.count, exemplar: count.exemplar };
    });
  }

  const firstExemplarInputRef = useRef(null);

  const orderedThemes = orderThemesBySelectedTheme(selectedResponse?.themes || [], selectedTheme);

  useEffect(() => {
    if (firstExemplarInputRef.current) {
      firstExemplarInputRef.current.focus();
    }
  }, [selectedResponse?.id, isModalOpen]);

  const prevalenceByThemeId = themesDisplayOrder.reduce((acc, themeId) => {
    acc[themeId] = tabularQuestionResponseTheme.find((theme) => theme.themeId === themeId)?.prevalence || 0;
    return acc;
  }, {});

  const exemplarCount = Object.values(counts).reduce((acc, { exemplar }) => (exemplar ? acc + exemplar : acc), 0);
  const questionOptions = [{ value: '', label: '' }, ...allQuestionIds.map((questionId, index) => ({ value: questionId, label: index + 1 }))];
  return (
    <div id="questionNavigator">
      <Modal isOpen={isModalOpen} style={DefaultModalStyles} parentSelector={() => document.querySelector('body')} onRequestClose={close}>
        <>
          <h2>Responses Navigator</h2>
          <h3>{selectedQuestion}</h3>
          <div style={{ display: 'flex', flexDirection: 'row', margin: '8px 0' }}>
            <p>{exemplarCount} exemplar responses</p>
            <div
              style={{ marginLeft: '20px', border: '1px solid black', padding: '2px 8px', position: 'relative' }}
              onMouseEnter={() => setShowQuestionsListTooltip(true)}
              onMouseLeave={() => setShowQuestionsListTooltip(false)}
            >
              See Questions List
              {showQuestionsListTooltip && (
                <div
                  style={{
                    position: 'absolute',
                    top: '35px',
                    left: '0px',
                    width: '600px',
                    padding: '8px',
                    backgroundColor: 'white',
                    border: '1px solid black',
                  }}
                >
                  {questionOptions.map(
                    ({ value, label }) =>
                      label && (
                        <p key={value}>
                          {label} - {value}
                        </p>
                      )
                  )}
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {themesDisplayOrder.map((themeId, index) => (
                  <li
                    key={index}
                    style={{ width: '330px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: '2em' }}
                  >
                    <div>
                      <input
                        id={`${themeId}-checkbox`}
                        name={`${themeId}-checkbox`}
                        type="checkbox"
                        checked={selectedResponse?.themes.map((t) => t.theme).includes(themeId)}
                        onChange={(e) => {
                          mergeUpdates({
                            action: e.target.checked ? 'AddTheme' : 'RemoveTheme',
                            options: { themeId, responseId: selectedResponse.responseId },
                          });
                        }}
                      />
                      <label htmlFor={`${themeId}-checkbox`}>{themesShorthands[themeId]}</label>
                    </div>
                    <div>
                      {prevalenceByThemeId[themeId]}% ({counts[themeId]?.exemplar || 0}/{counts[themeId]?.total || 0})
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <div>
                <div>
                  <button onClick={() => setSelectedResponseIndex((prevIndex) => Math.max(prevIndex - 1, 0))}>{'<'}</button>
                  <span>
                    {selectedResponseIndex + 1} of {selectedResponses.length}
                  </span>
                  <button onClick={() => setSelectedResponseIndex((prevIndex) => Math.min(prevIndex + 1, selectedResponses.length - 1))}>
                    {'>'}
                  </button>
                </div>
              </div>
              <div>
                <span>Response: {selectedResponse && selectedResponse.responseId}</span>
                <table>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Exemplar</th>
                      <th>Response</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedResponse &&
                      orderedThemes.map((theme, index) => (
                        <tr key={`${selectedQuestion}--${selectedResponse.responseId}--${theme.theme}`}>
                          <td>
                            <select
                              onChange={(e) => {
                                mergeUpdates({
                                  action: 'SetThemeQuestionOverride',
                                  options: { themeId: theme.theme, responseId: selectedResponse.responseId, questionOverride: e.target.value },
                                });
                              }}
                              defaultValue={theme.question_override || ''}
                            >
                              {questionOptions.map(({ value, label }) => (
                                <option key={value} value={value}>
                                  <div style={{ maxWidth: '20em' }}>{label}</div>
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <input
                              ref={index === 0 ? firstExemplarInputRef : null}
                              name={`${selectedQuestion}--${selectedResponse.responseId}--${theme.theme}`}
                              id={`${selectedQuestion}--${selectedResponse.responseId}--${theme.theme}`}
                              type="checkbox"
                              checked={theme.exemplar}
                              onChange={(e) => {
                                mergeUpdates({
                                  action: 'SetThemeExemplar',
                                  options: { themeId: theme.theme, responseId: selectedResponse.responseId, exemplar: e.target.checked },
                                });
                              }}
                            />
                            <label htmlFor={`${selectedQuestion}--${selectedResponse.responseId}--${theme.theme}`}>
                              {themesShorthands[theme.theme]}
                            </label>
                          </td>
                          <td>
                            <ThemeResponseOverrideTextArea
                              value={theme.text_overrides || selectedResponse.response_value}
                              onFinalChange={(text) => {
                                mergeUpdates({
                                  action: 'SetThemeOverride',
                                  options: { themeId: theme.theme, responseId: selectedResponse.responseId, textOverride: text },
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default ResponseTableNavigator;

const fallbackThemeId = '15'; // Other

const themeTemplate = (themeId) => {
  return {
    theme: themeId,
    text_overrides: null,
    exemplar: false,
    super_exemplar: false,
    question_override: null,
  };
};

const addThemeToResponse = (response, themeId) => {
  const newThemes = [...response.themes];
  newThemes.push(themeTemplate(themeId));
  newThemes.filter((theme) => theme.theme === 'themeId');
  return { ...response, themes: newThemes };
};

const removeThemeFromResponse = (response, themeId) => {
  const newThemes = response.themes.filter((theme) => theme.theme !== themeId);
  if (newThemes.length === 0) {
    return { ...response, themes: [themeTemplate(fallbackThemeId)] };
  }

  return { ...response, themes: newThemes };
};

const setThemeExemplar = (response, themeId, exemplar) => {
  const newThemes = response.themes.map((theme) => {
    if (theme.theme === themeId) {
      return { ...theme, exemplar };
    } else {
      return theme;
    }
  });
  return { ...response, themes: newThemes };
};

const setThemeOverride = (response, themeId, textOverride) => {
  const newThemes = response.themes.map((theme) => {
    if (theme.theme === themeId) {
      if (textOverride === '' || textOverride === response.response_value) {
        return { ...theme, text_overrides: null };
      }
      return { ...theme, text_overrides: textOverride };
    } else {
      return theme;
    }
  });

  return { ...response, themes: newThemes };
};

const setThemeQuestionOverride = (response, themeId, questionOverride) => {
  const usedOverride = questionOverride === '' ? null : questionOverride;

  const newThemes = response.themes.map((theme) => {
    if (theme.theme === themeId) {
      return { ...theme, question_override: usedOverride };
    } else {
      return theme;
    }
  });

  return { ...response, themes: newThemes };
};

const setThemeSuperExemplar = (response, themeId, superExemplar) => {
  const newThemes = response.themes.map((theme) => {
    if (theme.theme === themeId) {
      return { ...theme, super_exemplar: superExemplar };
    } else {
      return theme;
    }
  });

  return { ...response, themes: newThemes };
};

export const updateResponses = ({ responses, action, options }) => {
  return responses.map((response) => {
    if (response.id === options.responseId) {
      return updateResponse({ response, action, options });
    }
    return response;
  });
};

const updateResponse = ({ response, action, options }) => {
  switch (action) {
    case 'AddTheme':
      return addThemeToResponse(response, options.themeId);
    case 'RemoveTheme':
      return removeThemeFromResponse(response, options.themeId);
    case 'SetThemeExemplar':
      return setThemeExemplar(response, options.themeId, options.exemplar);
    case 'SetThemeSuperExemplar':
      return setThemeSuperExemplar(response, options.themeId, options.superExemplar);
    case 'SetThemeOverride':
      return setThemeOverride(response, options.themeId, options.textOverride);
    case 'SetThemeQuestionOverride':
      return setThemeQuestionOverride(response, options.themeId, options.questionOverride);
    default:
      return response;
  }
};

export default { updateResponses, addThemeToResponse, removeThemeFromResponse, setThemeExemplar, setThemeOverride, setThemeQuestionOverride };
